.overlay {
  font-size: large;
  color: red;
  position: absolute;
  z-index: 9000;
  background: Transparent no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
}

.App {
  text-align: center;
  color: white;
  background-color: black;
  height: 100vh;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.grid {
  width: 100vw;
  display: grid;
  justify-items: center;
  align-content: space-between;
  justify-content: space-around;
}

.grid-2 {
  grid-template-columns: repeat(auto-fill, minmax(50vw, 1fr));
}

.grid-4 {
  grid-template-columns: repeat(auto-fill, minmax(50vw, 1fr));
}

.grid-6 {
  grid-template-columns: repeat(auto-fill, minmax(33.3vw, 1fr));
}

.grid-9 {
  grid-template-columns: repeat(auto-fill, minmax(33.3vw, 1fr));
}

.embedcontainer {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  position: relative;
}

.embedcontainer-2 {
  width: 50vw;
  height: 100vh;
}

.embedcontainer-4 {
  width: 50vw;
  height: 50vh;
}

.embedcontainer-6 {
  width: 33.3vw;
  height: 50vh;
}

.embedcontainer-9 {
  width: 33.3vw;
  height: 33.3vh;
}


.youtube-player {
  width: 100%;
  height:100%;
  display: flex;
}

.App-link {
  color: #61dafb;
}
